














































































































































































































































































































































































































































































import { Component, Vue, Prop, Ref, Emit } from "vue-property-decorator";
import GameCard from "./components/GameCard.vue";
import FutureList from "./components/FutureList.vue";
import { ISupplyBox } from "@/api/supply-package.api";
import MemberApi from "@/api/member.api";
import { getModule } from "vuex-module-decorators";
import SupplyPackageStateStore from "@/store/modules/supply-package-state.store";
import DestinyCartoon from "@/views/member-equity/components/DestinyCartoon.vue";
import MemberPrice from "@/views/member-equity/components/MemberPrice.vue";
import MemberPriceSecond from "@/views/member-equity/components/MemberPriceSecond.vue";
import MemberBuyPop from "@/components/MemberBuyPop.vue";
import { List, Popup, Toast, Swipe, SwipeItem } from "vant";
import InteractionLib from "@/utils/interaction.lib";
import { getNow, systemPay } from "@/utils/common.util";
import { throttle } from "lodash";
import WalletPaymentSelector from "@/components/WalletPaymentSelector.vue";
import { StoreBuyProductResult } from "@/api/cap-store.api";
import { VanToast } from "vant/types/toast";
import CapStoreStateStore from "@/store/modules/cap-store-state.store";
import GlobalStateStore from "@/store/modules/global-state.store";
@Component({
  components: {
    GameCard,
    FutureList,
    DestinyCartoon,
    Popup,
    Swipe,
    SwipeItem,
    MemberPrice,
    MemberPriceSecond,
    WalletPaymentSelector,
    MemberBuyPop,
  },
})
export default class MemberPage extends Vue {
  @Ref("MemberBuyPop")
  private readonly MemberBuyPopRef!: MemberBuyPop;
  openList = [
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%85%8D%E8%B4%B9%402x.png",
      title: "免费大作",
      desc: "每月等你来领",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E7%A5%9E%E7%A7%98%402x.png",
      title: "神秘游戏",
      desc: "热门3A大作",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%9B%9E%E6%94%B6%402x.png",
      title: "稳赚回收",
      desc: "会费直接回本",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E4%BC%9A%E5%91%98%E4%BB%B7%402x.png",
      title: "会员价",
      desc: "金会员专属价",
    },
  ];
  openList2 = [
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E9%AA%A8%E6%8A%98%402x.png",
      title: "天天骨折",
      desc: "会员特权抢",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E8%92%B8%E6%B1%BD%E5%B8%81%402x.png",
      title: "消费返蒸汽币",
      desc: "省上加省",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E5%8B%8B%E7%AB%A0%402x.png",
      title: "专属勋章",
      desc: "金会员勋章",
    },
    {
      img: "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x.png",
      title: "敬请期待",
      desc: "更多权益",
    },
  ];

  @Ref("WalletPaymentSelector")
  private readonly paymentSelectorRef!: WalletPaymentSelector;

  supplyBox: Array<ISupplyBox> = [];
  supplyPackageState = getModule(SupplyPackageStateStore);
  wideHigh = 0;
  wideHeight = "";
  @Prop() MemberPageData!: any;
  globalState = getModule(GlobalStateStore);
  PayOrder: StoreBuyProductResult | undefined;
  PayToast: VanToast | undefined;
  pay_channel: number | undefined;
  ConfirmOutPopState: boolean | undefined;
  capStoreState = getModule(CapStoreStateStore);
  openMemberPage() {
    this.MemberBuyPopRef.hidden();
    this.isMemberPage = true;
    this.openPaypop();
  }
  isMemberPage = false;
  openProtocol() {
    if (InteractionLib.isApp()) {
      InteractionLib.openWebView("http://api.io.vgn.cn/steam_protocol.html");
      return;
    }
    location.href = "http://api.io.vgn.cn/steam_protocol.html";
  }
  toFreeDetail(index: number) {
    switch (index) {
      case 0:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(`${location.origin}/free-book`);
        }
        this.$router.push({ name: "freebook" });
        break;
      case 1:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/mysterious-game`
          );
        }
        this.$router.push({ name: "mysteriousgame" });
        break;
      case 2:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(`${location.origin}/sure-recycle`);
        }
        this.$router.push({ name: "surerecycle" });
        break;
      case 3:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/member-exclusive`

          );
        }
        this.$router.push({ name: "memberexclusive" });
        break;
      case 4:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/member-exclusive`
          );
        }
        this.$router.push({ name: "memberexclusive" });
        break;
      case 5:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(`${location.origin}/consume-coin`);
        }
        this.$router.push({ name: "consumecoin" });
        break;
      case 6:
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(`${location.origin}/member-badge`);
        }
        this.$router.push({ name: "memberbadge" });
        break;
    }
  }
  toFree() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/free-book`);
    }
    this.$router.push({ name: "freebook" });
  }
  toRecyle() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sure-recycle`);
    }
    this.$router.push({ name: "surerecycle" });
  }
  toSercet() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/mysterious-game`);
    }
    this.$router.push({ name: "mysteriousgame" });
  }
  toMember() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/member-exclusive`);
    }
    this.$router.push({ name: "memberexclusive" });
  }
  tobuyit(item: any) {
    if (item.behavior_data.code == 4) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(item.behavior_data.data);
      }
      window.location.href = item.behavior_data.data;
    }
  }
  toCoin() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/consume-coin`);
    }
    this.$router.push({ name: "consumecoin" });
  }
  toBadge() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/member-badge`);
    }
    this.$router.push({ name: "memberbadge" });
  }
  openPaypop() {
    this.paymentSelectorRef.show(99, (channel, deduction_wallet) => {
      this.payImmediatelyChange(channel, deduction_wallet);
      this.isMemberPage = false;
    });
  }
  async payImmediatelyChange(
    pay_channel: number,
    deduction_wallet?: boolean
  ): Promise<void> {
    const result = await this.capStoreState.buyProduct({
      id: this.MemberPageData.gold_vip_product_id,
      channel: pay_channel,
      deduction_wallet,
      openid: pay_channel === 3 ? this.globalState.openId || "" : "",
    });
    if (!result) return;
    if (pay_channel == 4) {
      Toast.clear();
      if (InteractionLib.isFlutter()) {
        return InteractionLib.openWebView(
          `${location.origin}/store/${this.MemberPageData.gold_vip_product_id}/result?order_no=${result.order_no}`
        );
      }
      await this.$router.push({
        name: "StoreResult",
        params: { id: String(this.MemberPageData.gold_vip_product_id) },
        query: { order_no: result.order_no },
      });
      return;
    }
    const toast = Toast.loading({
      message: "支付中...",
      duration: 0,
    });
    this.PayHandler(result, toast, pay_channel);
  }
  async PayHandler(
    order: StoreBuyProductResult,
    toast: VanToast,
    pay_channel: number
  ) {
    this.PayOrder = order;
    this.PayToast = toast;
    this.pay_channel = pay_channel;
    const result = await systemPay(pay_channel, order);
    toast.clear();
    if (!result && ![1, 2].includes(pay_channel)) {
      document.documentElement.style.overflowY = "hidden";
      this.ConfirmOutPopState = true;
      this.updateCoupon();
      Toast("支付取消");
    }
    if (!result && [1, 2].includes(pay_channel)) {
    }
    if (InteractionLib.isFlutter()) {
      InteractionLib.goldVipStatusNotice();
    }
    this.checkMember();
  }
  @Emit()
  checkMember() {
    return;
  }
  updateCoupon() {}
  async created() {
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) - 24;
    this.wideHeight = this.wideHigh / 3 + "px";
    this.supplyBox = await this.supplyPackageState.getSupplyBoxList(
      this.MemberPageData.gold_vip_box_id
    );
    setTimeout(()=>{
      InteractionLib.setWebViewTitle("会员中心");
    },3000)
  }
  show = false;
  get ShowBuyButton() {
    if (this.scrollTop > 600) {
      return true;
    }
    return false;
  }
  checkHotAll(id: number): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/special-sale/${id}`
      );
    }
    this.$router.push({
      name: "specialsale",
      params: { id: `${id}` },
      query: { time: String(getNow()), id: `${id}` },
    });
  }
  checkTimitAll() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/special/sales`);
    }
    this.$router.push({
      name: "SpecialSales",
      query: { time: String(getNow()) },
    });
  }
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  }
  scrollTop: any = 0;
  scrollToTop() {
    this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
  }
  openPop() {
    this.MemberBuyPopRef.show();
  }
}
