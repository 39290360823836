











































































import { countdown, getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import moment from "moment";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class FutureList extends Vue {
  @Prop() listOne!: any;
  @Prop() listTwo!: any;
  wideHigh = 0;
  wideHighStyle = "";
  realItems: any = [];
  created() {
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = clientWidth || offsetWidth;
    this.wideHigh = (this.wideHigh - 100) / 3;
    this.wideHighStyle = this.wideHigh + "px";
     let time = moment().add(3, 'M').format('YYYY年MM月')
    this.realItems.push(this.listOne);
    this.realItems.push(this.listTwo);
    this.realItems.push({
      title: "敬请期待",
      price: "???",
      cover:
        "https://cdn.vgn.cn/static/member/%E5%9B%BE%E6%A0%87-%E9%97%AE%E5%8F%B7.png",
      month: time,
    });
  }
   toDetail(id:any,index:number){
     if(index == 2) return
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${id}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
      query: { time: String(getNow()), id: `${id}` },
    });
  }
}
