









































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Popup, Loading, Toast } from "vant";
import BazaarApi from "@/api/bazaar.api";
import InteractionLib from "@/utils/interaction.lib";

type ICallback = (channel: number, deduction_wallet: boolean) => void;

@Component({
  components: {
    Popup,
    Loading,
  },
})
export default class WalletPaymentSelector extends Vue {
  private visible = false;
  @Prop() isMemberPage!:boolean
  @Prop() hide!:boolean

  private balance_loading = false;
  private balance_money = 0;

  private need_money = 0;
  private deduction_wallet = false;

  private callback: ICallback | undefined = undefined;

  private wechat = InteractionLib.mobile.wechat;

  private channel = this.wechat ? 3 : 2;

  private icons = {
    alipay: require("@/assets/alipay_icon.png"),
    wechat: require("@/assets/wechat_icon.png"),
    wallet: require("@/assets/wallet_icon.png"),
  };

  openProtocol() {
    if (InteractionLib.isApp()) {
      InteractionLib.openWebView("https://official.vgn.cn/purchase-notes.html");
      return;
    }
    location.href = 'https://official.vgn.cn/purchase-notes.html';
  }
 openProtocolUser() {
    if (InteractionLib.isApp()) {
      InteractionLib.openWebView("http://api.io.vgn.cn/steam_protocol.html");
      return;
    }
    location.href = 'http://api.io.vgn.cn/steam_protocol.html';
  }
  private get payment_channel() {
    const { alipay, wechat, wallet } = this.icons;
    const notWechat = [
      {
        icon: alipay,
        name: "支付宝",
        pay_channel: 1,
      },
    ];
    if(this.isMemberPage){
       return [
      ...(this.wechat ? [] : notWechat),
      {
        icon: wechat,
        name: "微信支付",
        pay_channel: this.wechat ? 3 : 2,
      }
    ];
    }
    return [
      ...(this.wechat ? [] : notWechat),
      {
        icon: wechat,
        name: "微信支付",
        pay_channel: this.wechat ? 3 : 2,
      },
      {
        icon: wallet,
        name: "蒸汽币：",
        pay_channel: 4,
      },
    ];
  }

  private get needLessThanBalanceMoney() {
    const { balance_money, need_money } = this;
    if (balance_money <= 0) return 0;
    return (need_money * 1000 - balance_money * 1000) / 1000;
  }

  private get balanceLessThanNeed() {
    const { balance_money, need_money } = this;
    if (balance_money <= 0) return false;
    return balance_money * 1000 - need_money * 1000 >= 0;
  }

  private handleChannel(pay_channel: number) {
    if (this.balance_loading) {
      Toast("还未准备好");
      return;
    }
    if (pay_channel === 4 && !this.balanceLessThanNeed) {
      this.deduction_wallet = !this.deduction_wallet;
      return;
    }
    this.channel = pay_channel;
  }

  private async getWalletMoney() {
    this.balance_loading = true;
    const { balance_money } = await BazaarApi.getMoney().catch(() => {
      Toast("钱包获取失败");
      return {
        balance_money: 0,
      };
    });
    this.balance_money = balance_money;
    this.balance_loading = false;
  }

  /**
   * @param price {number} 单位元
   * @param callback {ICallback} 回调
   */
  public show(price: number, callback: ICallback) {
    this.getWalletMoney();
    this.callback = callback;
    // console.log(price);
    this.need_money = price;

    if (this.channel === 4 && !this.balanceLessThanNeed) {
      this.channel = 1;
    }
    this.visible = true;
  }

  private handlePayClick() {
    const { channel, deduction_wallet, balance_loading } = this;
    if (balance_loading) {
      Toast("准备中...");
      return;
    }
    this.callback?.(channel, deduction_wallet);
    this.hidden();
  }

  public async hidden() {
    this.visible = false;
    setTimeout(() => {
      this.callback = undefined;
      this.need_money = 0;
    }, 100);
  }
}
